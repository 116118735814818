<template>
  <!-- 任务设定录入 -->
  <div class="entryPeople">
    <div class="top-bar">
      <div v-if="type === 1" :class="`item ${type === 1 ? 'item-choice' : ''}`">
        第1幕
      </div>
      <div
        @click="changeChapter(item)"
        :class="`item ${
          type === 2 && item.chapterId === chapterId ? 'item-choice' : ''
        }`"
        v-for="(item, i) in chapter"
        :key="i"
      >
        第{{ i + 1 }}幕
      </div>
    </div>
    <el-card class="content" v-loading="$store.state.loading">
      <!-- <people-act
        v-if="type === 1"
        ref="peopleMessage"
        :firstId="firstId"
        :key="chapterId"
        :chapterItem="chapterItem"
        :chapterId="chapterId"
        :chapterPersonId="chapterPersonId"
        :peopleId="peopleId"
        :scenarioId="scenarioId"
        @afterSave="afterSave"
        @changePersonType="changePersonType"
      /> -->
      <people-act
        :type="type"
        ref="peopleAct"
        :firstId="firstId"
        :key="chapterId"
        :chapterItem="chapterItem"
        :chapterId="chapterId"
        :chapterPersonId="chapterPersonId"
        :peopleId="peopleId"
        :scenarioId="scenarioId"
        @afterSave="afterSave"
        @changePersonType="changePersonType"
      />
    </el-card>
    <!-- 保存 -->
    <div
      style="
        position: absolute;
        bottom: -20px;
        right: 30px;
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
      "
    >
      <el-button
        v-if="isEdit"
        v-loading="loading"
        :disabled="loading"
        @click="save"
        style="
          border: 1px solid rgba(250, 225, 238, 1);
          background: rgba(250, 225, 238, 1);
          color: #333;
          font-weight: bold;
        "
        type="warning"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script>
// import peopleMessage from "./peopleMessage";
import peopleAct from "./components/peopleAct";
export default {
  components: {
    // peopleMessage,
    peopleAct,
  },
  props: ["peopleId", "pScenarioId"],
  data() {
    return {
      type: 2, // 1人物信息，2幕，3结局
      chapterId: "", // 幕ID
      person: null,
      chapter: [], // 幕数组
      chapterItem: {}, //单幕信息
      loading: false,
      scenarioId: "",
      chapterPersonId: "",
      firstId: "", // 第一幕的id
    };
  },
  created() {
    if (this.peopleId) {
      this.getPersonDetail();
    } else {
      // peopleId为0就是要添加了
      this.type = 1;
      this.getChaptersById(this.pScenarioId);
    }
  },
  computed: {
    isEdit() {
      return (
        (sessionStorage.getItem("isEdit") &&
          JSON.parse(sessionStorage.getItem("isEdit"))) ||
        (sessionStorage.getItem("privateFlag") &&
          JSON.parse(sessionStorage.getItem("privateFlag")))
      );
    },
  },
  methods: {
    changeChapter(item) {
      this.type = 2;
      this.chapterId = item.chapterId;
      this.chapterItem = item;
      this.chapterPersonId = item.id;
    },
    async getChaptersById(scenarioId) {
      const res = await this.getRequest.getChaptersById(scenarioId);
      this.$store.commit("setFirstChapterId", res[0].id);
      this.$store.commit("setFirstPersonId", res[0].scenarioPersonId);
    },
    async getPersonDetail() {
      this.$store.dispatch("setLoading", true);
      const {
        datas: { chapter, person },
      } = await this.getRequest.getPersonDetail(this.peopleId);
      if (chapter && chapter.length) {
        this.chapter = chapter;
        this.firstId = chapter[0].id;
        this.changeChapter(chapter[0]);
        // 把第一幕的chapter存到vuex
        this.$store.commit("setFirstChapterId", chapter[0].chapterId);
        this.$store.commit("setFirstPersonId", chapter[0].scenarioPersonId);
      }
      this.person = person;
      this.scenarioId = person.scenarioId;
      this.$store.dispatch("setLoading", false);
    },
    async save() {
      this.loading = true;
      try {
        if (this.type === 1 || this.type === 2) {
          await this.$refs.peopleAct.save();
        } else {
          await this.$refs.peopleEnding.save();
        }
      } finally {
        this.loading = false;
      }
    },
    async afterSave(peopleId) {
      // 传peopleId过来说明是添加人物成功了
      // 添加人物成功后重新获取人物列表
      this.afterAddPeople(this.peopleId || peopleId);
      const res = await this.getRequest.getPersonDetail(
        this.peopleId || peopleId
      );
      this.chapter = res.datas.chapter;
    },
    afterAddPeople(res) {
      this.$emit("afterAddPeople", res);
    },
    changePersonType(val) {
      for (let i = 0; i < this.chapter.length; i++) {
        this.chapter[i].personType = val;
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.entryPeople {
  height: 100%;
  position: relative;
  .top-bar {
    height: 30px;
    display: flex;
    .item {
      margin-right: 10px;
      padding: 4px;
      cursor: pointer;
      min-width: 80px;
      text-align: center;
    }
    .item-choice {
      background: rgba(127, 118, 189, 1);
      color: #fff;
      border-radius: 4px;
    }
  }
  .content {
    margin-top: 10px;
    height: calc(100% - 8px);
    overflow-y: scroll;
  }
}
</style>