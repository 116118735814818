<template>
  <!-- 题干组件 -->
  <div class="topic-content">
    <el-input size="small" style="width: 500px" v-model="content" placeholder="请输入题目内容">
      <template slot="append">
        <el-button @click="expend = !expend">
          {{ expend?'收起':'展开' }}
          <i :class="expend?'el-icon-caret-top':'el-icon-caret-bottom'"></i>
        </el-button>
      </template>
    </el-input>

    <transition name="el-zoom-in-top">
      <div class="detail-content" v-show="expend" v-html="content">
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["topicContent"],
  model: {
    prop: 'topicContent',
    event: 'pushContent'
  },
  data () {
    return {
      content: this.topicContent,
      expend: false
    }
  },
  watch: {
    topicContent (val) {
      this.content = val
    },
    content () {
      this.updateVal()
    }
  },
  methods: {
    updateVal () {
      this.$emit('pushContent', this.content)
    }
  }
}
</script>

<style lang='scss' scoped>
.topic-content {
  position: relative;
  .detail-content {
    border-left: 1px solid #DCDFE6;
    border-right: 1px solid #DCDFE6;
    border-bottom: 1px solid #DCDFE6;
    border-radius: 4px;
    background: #fff;
    position: absolute;
    top: 32px;
    font-size: 14px;
    width: 480px;
    padding: 0 10px;
  }
}
</style>