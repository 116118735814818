<template>
  <!-- 线索标签弹窗 -->
  <div>
    <!-- 优化需求-建立标签和所有线索的分类列表 -->
    <div class="label-clue" v-if="flag">
      <el-card
        style="margin: 10px 10px 10px 0"
        v-for="(items, indexs) in theatreList"
        :key="indexs"
      >
        <!-- 标签名 -->
        <div style="color: #7f76bd; display: flex; align-items: center">
          <div style="margin-right: 10px">
            {{ items.labelName }}({{ items.scenarioChapterPersonTheatreVOList.length }})
          </div>

          <i
            @click="editLabel(items, indexs)"
            class="el-icon-edit-outline"
            style="cursor: pointer; margin: 0 5px"
          ></i>

          <i
            @click="deleteLabel(items, indexs)"
            class="el-icon-circle-close"
            style="cursor: pointer; margin: 0 5px"
          ></i>
        </div>

        <div style="margin-top: 10px">
          <el-tag
            v-for="(item, index) in items.scenarioChapterPersonTheatreVOList"
            :key="index"
            size="mini"
            style="margin-right: 10px; margin-bottom: 5px"
            type="info"
          >
            <span style="cursor: pointer">
              {{ item.headline }}
            </span>
            <!-- <el-popover
              v-model="clue.showPopover"
              placement="right"
              width="200"
              trigger="click"
            >
              <div>
                移动至：
                <el-link
                  style="
                    color: #7f76bd;
                    margin-right: 14px;
                    margin-bottom: 10px;
                  "
                  v-for="(move, mi) in labelClueMap"
                  :key="mi"
                  @click="updateClueLabel(item, i, clue, ci, move, mi)"
                >
                  {{ move.labelName }}
                </el-link>
              </div>
              <i
                slot="reference"
                class="el-icon-rank"
                style="cursor: pointer; margin: 0 5px"
              ></i>
            </el-popover> -->
          </el-tag>
        </div>
      </el-card>
    </div>

    <el-input
      size="small"
      v-model="labelName"
      placeholder="标签名"
      style="width: 200px"
    ></el-input>
    <el-button
      type="primary"
      icon="el-icon-plus"
      style="margin-left: 10px"
      size="small"
      @click="addLabel"
    >
      添加标签
    </el-button>
  </div>
</template>

<script>
export default {
  props: ['peopleId','chapterId','theatreList'],
  data() {
    return {
      labelName: "",
      dictLabel: "",
      labelList: [],
      allClueList: [],
      labelClueMap: [], // 标签-线索分类map
      flag: 1,
      resChapterId: ''
    };
  },
  created() {
    this.resChapterId = this.chapterId || this.$store.state.firstChapterId
    this.scenarioId = localStorage.getItem('scenarioId')
    // this.getTheatreLabel(); // 查询线索标签列表
  },
  methods: {
    async getLabel() {
      const res = await this.getRequest.getScenarioLabel({
        scenarioId: this.scenarioId,
      });
      if (res.respCode === 0) {
        this.labelList = res.datas;
        this.getScenarioClue(); // 获取所有线索列表
      }
    },
    async getScenarioClue() {
      const res = await this.getRequest.getScenarioClue(this.scenarioId);
      this.allClueList = res.datas.sort((x, y) => {
        return x.chapterNum - y.chapterNum;
      });

      let map = [];
      // 处理标签
      this.labelList.forEach((val1) => {
        val1.linkClue = [];
        map.push(val1);
      });
      map.push({ labelName: "未关联线索", id: "0", linkClue: [] });
      // 处理线索数据
      this.allClueList.forEach((val2) => { 
        pushWhere(val2);
      });
      function pushWhere(item) {
        // 把线索放到哪个标签下面
        map.forEach((val3) => {
          if (val3.id == item.labelId) {
            val3.linkClue.push(item);
          }
        });
      }
      this.labelClueMap = map;
    },
    // 编辑标签名
    async editLabel(label, index) {
      // 点击标签待操作
      this.$prompt(label.labelName, "修改标签名", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      })
        .then(async (val) => {
          const res = await this.getRequest.updateScenarioLabel({
            id: label.id,
            labelName: val.value,
            scenarioId: this.scenarioId,
            chapterId: this.resChapterId,
          });
          if (res.respCode === 0) {
            this.theatreList[index].labelName = val.value;
          }
        })
        .catch(() => {});
    },
    // 删除标签
    async deleteLabel(label, index) {
      this.$confirm(`确认要删除-${label.labelName}吗？`, "删除标签", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const res = await this.getRequest.deleteScenarioLabel(label.id);
          if (res.respCode === 0) {
            this.theatreList.splice(index, 1);
          }
        })
        .catch(() => {});
    },
    async getTheatreLabel() {
      const res = await this.getRequest.getTheatreLabel({
        chapterPersonId: this.peopleId,
        scenarioId: this.scenarioId,
      })
      if(res.respCode === 0) {
        this.theatreList = res.datas
      }
    },
    // 增加标签
    async addLabel() {
      const res = await this.getRequest.addScenarioLabel({
        scenarioId: this.scenarioId,
        chapterId: this.resChapterId,
        labelName: this.labelName,
        labelType: 2
      });
      if (res.respCode === 0) {
        // this.getTheatreLabel()
        this.theatreList.push({
          id: res.datas,
          labelName: this.labelName,
          scenarioChapterPersonTheatreVOList: []
        })
        this.labelName = ''
      }
    },
  
  },
};
</script>

<style lang='scss' scoped>
.label-clue {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
</style>