import { render, staticRenderFns } from "./entryPeople.vue?vue&type=template&id=50e695f2&scoped=true"
import script from "./entryPeople.vue?vue&type=script&lang=js"
export * from "./entryPeople.vue?vue&type=script&lang=js"
import style0 from "./entryPeople.vue?vue&type=style&index=0&id=50e695f2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50e695f2",
  null
  
)

export default component.exports