<template>
  <!-- 幕 -->
  <div class="peopleAct">
    <div v-if="showPersonType" class="row m-b-20 flex-ac">
      <div class="tab-title">
        人物信息<span style="color: red; font-size: 16px">*</span>
      </div>
      <el-radio-group
        @change="changePersonType"
        size="mini"
        v-model="model.personType"
      >
        <el-radio-button :label="0">全部统一</el-radio-button>
        <el-radio-button :label="1">每幕不同</el-radio-button>
      </el-radio-group>
    </div>
    <div v-if="showPersonEdit">
      <div class="row m-b-10">
        <div class="computed-title">
          头像<span style="color: red; font-size: 16px">*</span>
        </div>
        <div style="margin-top: 20px">
          <el-avatar :size="80">
            <img
              :src="
                model.scenarioPersonBO.pictureVo
                  ? model.scenarioPersonBO.pictureVo.picturePath
                  : ''
              "
            />
          </el-avatar>
          <choice-image
            :text="`选择头像`"
            style="margin-top: 10px"
            @surePush="
              (res) => {
                model.scenarioPersonBO.pictureVo = res;
              }
            "
          />
          <choice-image
            :text="`选择半身照`"
            style="margin-top: 10px"
            :viewUrl="
              model.scenarioPersonBO.appearancePicture &&
              model.scenarioPersonBO.appearancePicture.picturePath
            "
            @surePush="
              (res) => {
                model.scenarioPersonBO.appearancePicture = res;
              }
            "
          />
        </div>
      </div>

      <div class="row m-b-10">
        <div class="computed-title">
          名称<span style="color: red; font-size: 16px">*</span>
        </div>
        <el-input
          v-model="model.scenarioPersonBO.personName"
          style="width: 200px"
          placeholder="人物名称"
          size="mini"
        ></el-input>
      </div>

      <div class="row m-b-10">
        <div class="computed-title">
          人物简介<span style="color: red; font-size: 16px">*</span>
        </div>
        <div>
          <el-input
            style="width: 600px"
            rows="6"
            type="textarea"
            v-model="model.scenarioPersonBO.personSynopsis"
            size="small"
            placeholder="一句话简介"
          ></el-input>
        </div>
      </div>
      <el-divider></el-divider>
    </div>
    <div class="row">
      <div class="tab-title">故事</div>
      <div style="margin-left: 20px; width: calc(100% - 80px)">
        <div style="display: flex; justify-content: flex-end">
          <edit-text :content="model.storyContent" @surePush="changegs" />
        </div>
        <div class="eb-text-area" v-html="model.storyContent"></div>
      </div>
    </div>
    <el-divider></el-divider>

    <div class="row">
      <div class="tab-title">任务</div>
      <div style="margin-left: 20px; width: calc(100% - 80px)">
        <div style="display: flex; justify-content: flex-end">
          <edit-text :content="model.taskContent" @surePush="changerw" />
        </div>
        <div
          class="eb-text-area"
          style="height: 150px"
          v-html="model.taskContent"
        ></div>
      </div>
    </div>
    <el-divider></el-divider>

    <!-- 小剧场theatre kkkkk-->
    <div class="row">
      <div class="tab-title">小剧场</div>
      <div style="margin-left: 20px; width: calc(100% - 80px)">
        <div style="display: flex; justify-content: flex-end">
          <div class="theatre-tab" @click="threatreDialog = true">
            小剧场标签
          </div>
        </div>
      </div>
    </div>

    <div
      class="theatre-content"
      v-for="(item, indexs) in model.theatreDTOList"
      :key="indexs"
    >
      <div class="flex-between">
        <div>事件{{ indexs + 1 }}</div>
        <el-button
          plain
          size="mini"
          type="danger"
          @click="delTheatre(indexs)"
          style="margin-left: 20px"
          >删除剧情</el-button
        >
      </div>
      <div class="flex">
        <div>标签：</div>
        <el-select
          size="mini"
          style="width: 212px; margin-left: 68px"
          v-model="item.labelId"
          placeholder="请选择"
        >
          <el-option
            v-for="(theaitem, i) in theatreOption"
            :key="i"
            :label="theaitem.labelName"
            :value="theaitem.id"
          >
          </el-option>
        </el-select>
        <div class="remark-text">（默认关联到“小剧场”标签栏）</div>
      </div>
      <div class="flex">
        <div>标题：</div>
        <el-input
          v-model="item.headline"
          size="mini"
          style="width: 212px; margin-left: 68px"
        ></el-input>
        <div class="remark-text">（默认显示“小剧场1”）</div>
      </div>
      <div class="flex-between" style="overflow: hidden; margin-top: 10px">
        <div>正文：</div>
        <edit-text
          :content="item.content"
          @surePush="changejc($event, indexs)"
        />
      </div>
      <div style="padding-left: 110px">
        <div
          class="eb-text-area"
          style="height: 150px"
          v-html="item.content"
        ></div>
      </div>
      <div style="display: flex" class="mt-3">
        <div class="mt-1" style="margin-right: 68px">音频：</div>
        <ChoiceAudio
          :audioVO="item.scenarioAudioBaseDTO"
          @putAudio="putAudio(item, $event)"
          :playMode="1"
        />
      </div>
    </div>
    <div
      style="
        margin-top: 20px;
        width: 100%;
        display: flex;
        justify-content: center;
      "
    >
      <el-button icon="el-icon-plus" @click="addTheatre">添加</el-button>
    </div>
    <el-divider></el-divider>

    <!--剧场标签弹窗 -->
    <el-dialog :visible.sync="threatreDialog" :close-on-click-modal="false">
      <common-clue-dialog
        v-if="threatreDialog"
        :theatreList="theatreOption"
        :peopleId="peopleId"
        :chapterId="chapterId"
      />
    </el-dialog>

    <div>
      <div class="tab-title">题目</div>
      <div style="margin-top: 20px">
        <div
          v-for="(item, i) in model.topicBoList"
          :key="i"
          style="margin-bottom: 50px"
        >
          <!-- 第一行题干 -->
          <div class="rowac">
            <div style="width: 100px">题目{{ i + 1 }}：</div>
            <topic-content v-model="item.topicContent" />
            <el-button
              plain
              size="mini"
              type="danger"
              @click="deleteTopic(i)"
              style="margin-left: 20px"
              >删除题目</el-button
            >
          </div>
          <!-- 第二行题型 -->
          <div class="rowac" style="margin-top: 10px">
            <div style="width: 100px">题型：</div>
            <div>
              <el-button
                style="margin-right: 20px"
                @click="changeTopicType(item, 1)"
                :plain="item.topicType !== 1"
                size="small"
                type="primary"
                >单选</el-button
              >
              <el-button
                style="margin-right: 20px"
                @click="changeTopicType(item, 2)"
                :plain="item.topicType !== 2"
                size="small"
                type="primary"
                >多选</el-button
              >
              <el-button
                style="margin-right: 20px"
                @click="changeTopicType(item, 3)"
                :plain="item.topicType !== 3"
                size="small"
                type="primary"
                >填空</el-button
              >
            </div>
          </div>
          <!-- 第三行选项答案 -->
          <div class="row" style="margin-top: 12px">
            <div style="width: 100px">答案：</div>
            <div>
              <!-- 单选 -->
              <el-radio-group
                v-if="item.topicType === 1"
                v-model="item.topicAnswer[0]"
              >
                <div
                  class="rowac"
                  style="margin-top: 10px"
                  v-for="(option, oi) in item.scenarioTopicOptionVoList"
                  :key="oi"
                >
                  <el-radio
                    style="width: 40px"
                    :label="option.optionNumber"
                  ></el-radio>
                  <el-input
                    v-model="option.optionContent"
                    style="width: 435px"
                    size="small"
                  ></el-input>
                  <el-input-number
                    v-model="option.score"
                    style="margin: 0 10px; width: 100px"
                    size="small"
                    controls-position="right"
                    :min="-50"
                    :max="100"
                  >
                  </el-input-number>
                  <el-button
                    @click="deleteOption(i, oi)"
                    size="mini"
                    circle
                    type="danger"
                    plain
                    icon="el-icon-close"
                  ></el-button>
                </div>
              </el-radio-group>

              <!-- 多选 -->
              <el-checkbox-group
                v-if="item.topicType === 2"
                v-model="item.topicAnswer"
              >
                <div
                  class="rowac"
                  style="margin-top: 10px"
                  v-for="(option, oi) in item.scenarioTopicOptionVoList"
                  :key="oi"
                >
                  <el-checkbox
                    :label="option.optionNumber"
                    style="width: 40px; margin-right: 30px"
                  ></el-checkbox>
                  <el-input
                    v-model="option.optionContent"
                    style="width: 435px"
                    size="small"
                  ></el-input>
                  <el-input-number
                    v-model="option.score"
                    style="margin: 0 10px; width: 100px"
                    size="small"
                    controls-position="right"
                    :min="-50"
                    :max="100"
                  >
                  </el-input-number>

                  <el-button
                    @click="deleteOption(i, oi)"
                    size="mini"
                    circle
                    type="danger"
                    plain
                    icon="el-icon-close"
                  ></el-button>
                </div>
              </el-checkbox-group>

              <!-- 新增选项按钮 -->
              <div style="margin-top: 10px" v-if="item.topicType !== 3">
                <el-button
                  type="text"
                  icon="el-icon-plus"
                  plain
                  size="mini"
                  @click="addOption(i)"
                  >添加选项</el-button
                >
              </div>

              <!-- 填空 -->
              <div v-if="item.topicType === 3" class="rowac">
                <el-input
                  v-model="item.topicAnswer[0]"
                  style="width: 435px"
                  size="small"
                ></el-input>
                <el-input-number
                  v-model="item.topicAnswerScore"
                  style="margin: 0 10px; width: 120px"
                  size="small"
                  controls-position="right"
                  :min="0"
                  :max="10"
                >
                </el-input-number>
                <div style="line-height: 28px">分</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="margin-top: 20px; display: flex; justify-content: center">
      <el-button icon="el-icon-plus" @click="addTopic">添加</el-button>
    </div>
  </div>
</template>

<script>
import editText from "@/components/common/editText";
import topicContent from "@/components/common/topicContent";
import commonClueDialog from "./commonClueDialog";
import ChoiceAudio from "@compts/common/ChoiceAudio.vue";
import choiceImage from "@/components/common/choiceImage";
export default {
  components: {
    topicContent,
    editText,
    commonClueDialog,
    ChoiceAudio,
    choiceImage,
  },
  props: {
    chapterId: {
      default: "",
    },
    peopleId: {
      default: "",
    },
    chapterItem: {
      default: () => {},
    },
    scenarioId: {
      type: String,
    },
    chapterPersonId: {
      type: String,
    },
    firstId: {
      type: String,
    },
    type: {
      type: Number,
    },
  },
  data() {
    return {
      threatreDialog: false,
      theatreOption: [],
      model: {
        sss: 111,
        scenarioPersonId: "",
        chapterId: "",
        storyContent: "",
        taskContent: "",
        personType: 0,
        topicBoList: [
          {
            id: "",
            topicContent: "",
            topicType: 1, // 1单选2多选3填空
            topicAnswer: ["A"], // 答案
            topicAnswerScore: "",
            scenarioTopicOptionVoList: [
              {
                id: "",
                optionNumber: "A", // 选项序号
                optionContent: "", // 选项内容
                score: 0, // 选中得分
                sortNo: 1,
              },
              {
                id: "",
                optionNumber: "B", // 选项序号
                optionContent: "", // 选项内容
                score: 0, // 选中得分
                sortNo: 2,
              },
              {
                id: "",
                optionNumber: "C", // 选项序号
                optionContent: "", // 选项内容
                score: 0, // 选中得分
                sortNo: 3,
              },
              {
                id: "",
                optionNumber: "D", // 选项序号
                optionContent: "", // 选项内容
                score: 0, // 选中得分
                sortNo: 4,
              },
            ],
          },
        ],
        scenarioPersonBO: {
          scenarioId: localStorage.getItem("scenarioId"),
          pictureVo: {
            id: 0,
            picturePath: "",
          },
          appearancePicture: {
            id: 0,
            picturePath: "",
          },
          personName: "",
          selfIntroduce: "",
          simplePersonSynopsis: "",
          personSynopsis: "",
          // scenarioPersonSkillVo: [
          //   {
          //     id: "",
          //     sortNo: 1,
          //     skillContent: "",
          //   },
          //   {
          //     id: "",
          //     sortNo: 2,
          //     skillContent: "",
          //   },
          // ],
        },
        theatreDTOList: [],
        theatreVOList: [],
        isHaveTheatreLabel: "", //是否拥有小剧场标签
      },
    };
  },
  created() {
    // this.model.scenarioPersonId = this.peopleId || 0;
    this.model.chapterId = this.chapterId;
    // 合并
    Object.assign(this.model, this.chapterItem);
    this.dealParam();
    this.scenarioId && this.getTheatreLabel();
  },
  computed: {
    showPersonEdit() {
      return (
        this.firstId === this.chapterItem.id ||
        this.model.personType ||
        !this.chapterId
      );
    },
    showPersonType() {
      return this.firstId === this.chapterItem.id || !this.chapterId;
    },
  },
  watch: {
    // chapterItem: {
    //   handler() {
    //     if (this.chapterItem.scenarioPersonId) {
    //       this.model.scenarioPersonId = this.peopleId;
    //       this.model.chapterId = this.chapterId;
    //       this.model = this.chapterItem;
    //     }
    //   },
    //   deep: true,
    // },
  },
  methods: {
    changePersonType(val) {
      if (this.model.id) {
        const tempVal = val == 0 ? 1 : 0;
        this.model.personType = tempVal;
        this.$confirm("确定切换人物信息吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.model.personType = val;
          this.save({ tempVal });
          this.$emit("changePersonType", val);
        });
      }
    },
    putAudio(item, v) {
      if (v) {
        item.scenarioAudioBaseDTO = {
          playMode: v.playMode,
          materialAudioId: v.materialAudioId || v.id,
          url: v.audioUrl,
          audioTotalTime: v.audioTotalTime,
        };
        // item.scenarioAudioBaseDTO.playMode = v.playMode;
        // (item.scenarioAudioBaseDTO.materialAudioId = v.materialAudioId || v.id),
        //   (item.scenarioAudioBaseDTO.url = v.audioUrl);
        // item.scenarioAudioBaseDTO.audioTotalTime = v.audioTotalTime;
      } else {
        item.scenarioAudioBaseDTO = null;
      }

      // item.scenarioAudioVO = v
      //   ? {
      //       playMode: v.playMode,
      //       materialAudioId: v.id,
      //       relevanceId: item.id,
      //       scenarioId: this.scenarioId
      //     }
      //   : null;
    },
    delTheatre(index) {
      this.$confirm("确定删除此小剧场吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const { theatreDTOList } = this.model;
        theatreDTOList.splice(index, 1);
      });
    },
    addTheatre() {
      this.model.theatreDTOList.push({
        headline: `小剧场${this.model.theatreDTOList.length + 1}`,
        chapterPersonId: this.chapterPersonId,
        labelId: this.isHaveTheatreLabel || "",
        labelName: "",
        id: "",
        content: "",
        scenarioAudioBaseDTO: null,
      });
    },
    dealParam() {
      const theatreDTOList = this.model.theatreVOList || [];
      theatreDTOList &&
        theatreDTOList.forEach((item) => {
          item.scenarioAudioBaseDTO = item.scenarioAudioVO;
          this.$delete(item, "scenarioAudioVO");
        });
      this.$delete(this.model, "theatreVOList");
      this.$set(this.model, "theatreDTOList", theatreDTOList);
    },
    changegs(res) {
      this.model.storyContent = res;
    },
    changerw(res) {
      this.model.taskContent = res;
    },
    changejc(res, index) {
      this.model.theatreDTOList[index].content = res;
    },
    addTopic() {
      // 新增题目
      this.model.topicBoList.push(this.$options.data().model.topicBoList[0]);
    },
    deleteTopic(i) {
      // 删除题目
      this.model.topicBoList.splice(i, 1);
    },
    changeTopicType(item, type) {
      item.topicType = type;
      item.topicAnswer = [];
    },
    addOption(i) {
      // 新增选项
      this.model.topicBoList[i].scenarioTopicOptionVoList.push({
        id: "",
        optionNumber: String.fromCharCode(
          Number(
            65 + this.model.topicBoList[i].scenarioTopicOptionVoList.length
          )
        ), // 选项序号
        optionContent: "", // 选项内容
        score: 0, // 选中得分
        sortNo: this.model.topicBoList[i].scenarioTopicOptionVoList.length + 1,
      });
    },
    deleteOption(i, oi) {
      // 删除选项
      this.model.topicBoList[i].scenarioTopicOptionVoList.splice(oi, 1);
      // 重新排序
      this.model.topicBoList[i].scenarioTopicOptionVoList.forEach(
        (element, i) => {
          element.optionNumber = String.fromCharCode(Number(65 + i));
          element.sortNo = i + 1;
        }
      );
    },
    async getTheatreLabel() {
      const res = await this.getRequest.getTheatreLabel({
        chapterPersonId: this.peopleId,
        scenarioId: this.scenarioId,
      });
      if (res.respCode === 0) {
        const theaList = res.datas;
        const item = theaList.find((item) => item.labelName === "小剧场");
        item && (this.isHaveTheatreLabel = item.id);
        this.theatreOption = theaList;
      }
    },
    async save(isChange) {
      if (!this.model.scenarioPersonBO.personName) {
        this.$message({
          message: "请输入人物姓名",
          type: "warning",
        });
        return;
      }
      if (!this.model.scenarioPersonBO.personSynopsis) {
        this.$message({
          message: "请输入你的故事",
          type: "warning",
        });
        return;
      }
      if (!this.model.scenarioPersonBO.appearancePicture.id) {
        this.$message({
          message: "请选择头像",
          type: "warning",
        });
        return;
      }
      if (!this.model.scenarioPersonBO.pictureVo.id) {
        this.$message({
          message: "请选择半身照",
          type: "warning",
        });
        return;
      }
      let id = this.model.id;
      let fun = "updatePersonScenario";
      if (!id) {
        fun = "addPersonScenario";
        // 区别有人物加幕和添加人物的区别
        this.model.chapterId =
          this.chapterId || this.$store.state.firstChapterId;
        // 没有chapterId就是有人物加幕
        // if (this.chapterId) {
        // }
      }
      // this.model.firstScenarioPersonId = this.$store.state.firstPersonId;
      // if (this.model.personType) {
      //   this.
      // }
      const res = await this.getRequest[fun](this.model);
      if (res.respCode === 0) {
        this.$message({
          message: res.respMsg,
          type: "success",
        });
        this.model.id = res.datas.id;
        this.model.scenarioPersonId = res.datas.scenarioPersonId;
        // this.dealParam();

        // 添加就传新建人物的id过去，编辑传null
        this.$emit("afterSave", res.datas.scenarioPersonId);
      } else {
        isChange && (this.model.personType = isChange.tempVal);
        this.$message({
          message: res.respMsg,
          type: "error",
        });
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.el-divider {
  background: rgba(236, 234, 250, 1);
}
.tab-title {
  width: 80px;
  height: 24px;
  color: rgba(51, 51, 51, 1);
  background: rgba(236, 234, 250, 1);
  border-radius: 4px;
  padding: 0 4px;
  line-height: 24px;
  text-align: center;
  margin-right: 30px;
}
.row {
  display: flex;
}
.rowac {
  display: flex;
  align-items: center;
}
.eb-text-area {
  margin-top: 10px;
  overflow-y: scroll;
  border-radius: 4px;
  border: 1px solid rgba(197, 192, 224, 1);
  height: 250px;
  padding: 0 10px;
  white-space: pre-wrap;
}
.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
}

.remark-text {
  color: #999999;
  margin-left: 20px;
}
.theatre-tab {
  width: 212px;
  height: 24px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #c5c0e0;
  font-size: 14px;
  text-align: center;
  line-height: 24px;
  cursor: pointer;
}
.theatre-content {
  margin-top: 40px;
  margin-bottom: 20px;
  padding-left: 20px;
  font-size: 14px;
  .del {
    width: 76px;
    height: 24px;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #f394ac;
    line-height: 21px;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #f394ac;
  }
}
.computed-title {
  padding: 3px 8px;
  font-weight: 400;
  font-size: 15px;
  margin-right: 40px;
  width: 70px;
  text-align: center;
  border-left: 4px solid #c5c0e0;
  height: 20px;
}
</style>